import gql from 'graphql-tag';

export const HighlightKeywordsQuery = gql`
	query HighlightKeywordsQuery($pageARI: String, $workspaceId: String) {
		knowledgeDiscovery {
			keyPhrases(entityAri: $pageARI, workspaceId: $workspaceId)
				@optIn(to: "KnowledgeDiscovery Get Key Phrases") {
				__typename
				... on KnowledgeDiscoveryKeyPhraseConnection {
					nodes {
						... on KnowledgeDiscoveryKeyPhrase {
							keyPhrase
							category
						}
					}
				}
				... on QueryError {
					message
				}
			}
		}
	}
`;
