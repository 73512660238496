import { useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { usePageSpaceKey } from '@confluence/page-context';
import { getAGGClient } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { useSpaceId } from '@confluence/space-utils';
import { TOUCH } from '@confluence/navdex';

import type { SpaceViewsPage } from './SpaceViewsList/SpaceViewsList';
import { ContentTreeSearchQuery } from './ContentTreeSearchQuery.agggraphql';

const SEARCH_PAGE_SIZE = 50;
const transformSearchData = (data) => {
	const nodes = data?.search?.search?.edges?.map((edge) => edge.node) || undefined;
	return nodes?.map(({ entityId, title, type, confluenceEntity }) => ({
		id: entityId,
		title,
		displayEmoji: confluenceEntity?.metadata?.titleEmojiPublished?.value,
		type,
		// TODO: Show super admins which pages are restricted -- this info is not yet available in AGG
		hasRestrictions: false,
		hasInheritedRestrictions: false,
		// TODO: DISCO-774 Add draft support to SpaceViewsList
	}));
};

type ContentTreeSearchResult = {
	searchResult?: SpaceViewsPage[];
	loading: boolean;
	error?: ApolloError;
};
export const useContentTreeSearch = (searchText: string): ContentTreeSearchResult => {
	const [spaceKey] = usePageSpaceKey();
	const { cloudId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const spaceId = useSpaceId(spaceKey);

	const { data, loading, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentTreeSearchQuery,
		{
			variables: {
				searchText,
				experience: 'confluence.advancedSearch', // TODO: this should be specific to this experience
				first: SEARCH_PAGE_SIZE,
				filters: {
					entities: [
						'ati:cloud:confluence:page',
						'ati:cloud:confluence:whiteboard',
						'ati:cloud:confluence:database',
						'ati:cloud:confluence:embed',
					],
					locations: ['ari:cloud:confluence::site/'.concat(cloudId)],
					confluenceFilters: {
						spacesFilter: [spaceKey],
						contentStatuses: ['CURRENT'],
						titleMatchOnly: true,
					},
				},
			},
			client: getAGGClient(),
			skip: searchText.length === 0,
		},
	);

	const searchResult = useMemo(() => transformSearchData(data), [data]);

	useEffect(() => {
		if (searchResult) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'searched',
					actionSubject: 'contentTree',
					source: 'sideNavigation',
					containerId: spaceId,
					containerType: 'space',
					attributes: {
						searchResultCount: searchResult.length,
						navdexPointType: TOUCH,
					},
				},
			}).fire();
		}
	}, [createAnalyticsEvent, searchResult, spaceId]);

	return {
		searchResult,
		loading,
		error,
	};
};
