import { keyframes } from '@compiled/react';
import type { ComponentType, FC, ReactNode } from 'react';
import React, { useCallback, useRef } from 'react';
import type { WrappedComponentProps } from 'react-intl-next';

import LinkItem from '@atlaskit/menu/link-item';
import { Box, Grid, Inline, xcss } from '@atlaskit/primitives';
import { B400, B50, N20, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { CSSFn, ItemState } from '@atlaskit/menu/types';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import { invalidEmojiRegex } from '@confluence/emoji-title/entry-points/helpers';
import { ContentTreeIconLoader as ContentTreeIcon } from '@confluence/icons/entry-points/ContentTreeIcon';
import type { ContentTreeTypes } from '@confluence/icons/entry-points/contentTreeTypes';
import { includesValidContentTreeType } from '@confluence/icons/entry-points/contentTreeTypes';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { ANIMATE_TIME_SEC, ConditionalTreeItemTooltip } from '@confluence/page-tree';
import { ConditionalInlineRename } from '@confluence/page-tree/entry-points/InlineRename';
import type { IconItemType } from '@confluence/page-tree/entry-points/PageTreeAfterIcon';
import { PageTreeAfterIcon } from '@confluence/page-tree/entry-points/PageTreeAfterIcon';

import { useIsAdvancedSearchInTreeEnabled } from '../useIsAdvancedSearchInTreeEnabled';

type SpaceViewsListItemProps = {
	title: string;
	url: string;
	onItemClick?: (id: string, index?: number) => void;
	isSelected: boolean;
	item: IconItemType;
	spaceKey: string;
	isSuperAdmin: boolean;
	highlight?: boolean;
	displayEmoji?: string;
	contentType?: string;
	isHoverPageCardOptedIn?: boolean;
	closeAllHoverPageCards?: () => void;
	setOpenQuickActionsId: (state: string | null) => void;
	isQuickActionsOpen: boolean;
	isQuickActionsFocused: boolean;
	setFocusedQuickActionsId: (focusedQuickActionsId: string | null) => void;
	isEditingTitleId: string | null;
	setIsEditingTitleId: (isEditingTitleId: string | null) => void;
	onEnterHoverTarget?: (params: {
		itemID: string;
		isQuickActionsFocused: boolean;
		hoverPageCardTriggerRef: React.RefObject<HTMLDivElement>;
	}) => void;
	onLeaveHoverTarget?: () => void;
	onBlurHoverTarget?: (e) => void;
	shouldRenderAfterIcon?: boolean;
	index?: number;
	renderTitle?: ({ title }: { title: string }) => ReactNode;
};

const EmojiOrContentIcon = ({
	isContentTreeIconVisible,
	contentType,
	iconColor,
	displayEmoji,
	isNav4Enabled = false,
}) => {
	const isEmbedWithoutEmoji = contentType === 'embed' && !displayEmoji;

	if (isContentTreeIconVisible && !displayEmoji) {
		return (
			<Box
				as="span"
				xcss={[isNav4Enabled ? contentIconWrapperNav4Styles : contentIconWrapperStyles]}
			>
				<ContentTreeIcon type={contentType as ContentTreeTypes} label="" color={iconColor} />
			</Box>
		);
	} else if ((displayEmoji && !invalidEmojiRegex.test(displayEmoji)) || isEmbedWithoutEmoji) {
		return (
			<LazyEmojiComponentLoader
				emoji={displayEmoji}
				height={16}
				context="spaceViews"
				wrapper={isNav4Enabled ? Nav4EmojiWrapper : EmojiWrapper}
				contentType={contentType}
			/>
		);
	} else {
		return null;
	}
};

const HIGHLIGHT_FADE = keyframes({
	from: {
		background: token('color.background.accent.blue.subtlest', B50),
	},
	to: {
		background: 'none',
	},
});

const standardTextColor = token('color.text.subtle', N500);

const getCSSFn =
	(
		forceHoverBackground: boolean,
		highlight: boolean,
		isQuickActionsFocused: boolean,
		isAdvancedSearchInTreeEnabled: boolean,
	) =>
	({ isSelected = false }) => {
		const color = token('color.text', '#44546F');
		return {
			textDecoration: 'none',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			borderRadius: '3px',
			minHeight: '36px',
			backgroundColor: isSelected ? token('color.background.selected', B50) : 'transparent',
			height: '36px',
			padding: '6px 4px 6px 8px',
			span: {
				columnGap: '0px',
			},
			color,
			'&:before': {
				content: 'none',
			},
			'&:visited, &:active, &:focus, &:hover': {
				color: isSelected
					? `${token('color.text.selected', '#0c66e4')} !important`
					: standardTextColor,
				textDecoration: 'none',
			},
			'&:active, &:focus': {
				textDecoration: 'none',
			},
			'&:hover': {
				color,
			},
			'& strong': isAdvancedSearchInTreeEnabled
				? {
						backgroundColor: token('color.background.accent.yellow.subtler', '#F8E6A0'),
						padding: token('space.025', '2px'),
						borderRadius: token('border.radius', '3px'),
					}
				: {},

			animation: highlight ? `${HIGHLIGHT_FADE} ${ANIMATE_TIME_SEC}s linear` : 'none',

			'--display-after-icon': forceHoverBackground || isQuickActionsFocused ? 'flex' : 'none',
			'--opacity-after-icon': forceHoverBackground || isQuickActionsFocused ? '100%' : '0%',
			'&:hover, &:focus-within': {
				'--display-after-icon': 'flex',
				'--opacity-after-icon': '100%',
				backgroundColor: isSelected
					? token('color.background.selected.hovered', '#cce0ff')
					: token('color.background.neutral.subtle.hovered', N20),
			},
		};
	};

const EmojiWrapperStyles = xcss({
	display: 'flex',
	flexShrink: 0,
	alignItems: 'center',
	marginRight: 'space.075',
});

const EmojiWrapper: ComponentType<{}> = ({ children }) => {
	return (
		<Inline as="span" xcss={EmojiWrapperStyles}>
			{children}
		</Inline>
	);
};

const Nav4EmojiWrapperStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexShrink: 0,
});

const Nav4EmojiWrapper: ComponentType<{}> = ({ children }) => {
	return (
		<Inline as="span" xcss={Nav4EmojiWrapperStyles}>
			{children}
		</Inline>
	);
};

export const SpaceViewsListItem: FC<SpaceViewsListItemProps & WrappedComponentProps> = ({
	title,
	url,
	onItemClick,
	isSelected,
	item,
	displayEmoji = '',
	contentType,
	spaceKey,
	isSuperAdmin = false,
	highlight = false,
	isHoverPageCardOptedIn = false,
	closeAllHoverPageCards = () => {},
	setOpenQuickActionsId,
	isQuickActionsOpen,
	isQuickActionsFocused,
	setFocusedQuickActionsId,
	isEditingTitleId,
	setIsEditingTitleId,
	onEnterHoverTarget = () => {},
	onLeaveHoverTarget = () => {},
	onBlurHoverTarget = () => {},
	shouldRenderAfterIcon,
	index,
	renderTitle = ({ title }) => title,
}) => {
	const hoverPageCardTriggerRef = useRef<HTMLDivElement>(null);

	const { isAdvancedSearchInTreeEnabled } = useIsAdvancedSearchInTreeEnabled();
	const isNav4Enabled = useIsNav4Enabled();
	const onClick = useCallback(() => {
		onItemClick && onItemClick(item.id, index);
		closeAllHoverPageCards();
	}, [onItemClick, item.id, index, closeAllHoverPageCards]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const CSSFn = useCallback(
		getCSSFn(isQuickActionsOpen, highlight, isQuickActionsFocused, isAdvancedSearchInTreeEnabled),
		[isQuickActionsOpen, highlight, isQuickActionsFocused, isAdvancedSearchInTreeEnabled],
	) as CSSFn<ItemState>;

	const iconColor = isSelected ? token('color.icon.selected', B400) : undefined;

	const isContentTreeIconVisible =
		contentType && includesValidContentTreeType(contentType, isNav4Enabled);

	if (isNav4Enabled) {
		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				ref={hoverPageCardTriggerRef}
				onBlur={onBlurHoverTarget}
				onMouseEnter={() =>
					onEnterHoverTarget({
						itemID: item.id.toString(),
						isQuickActionsFocused,
						hoverPageCardTriggerRef,
					})
				}
				onMouseLeave={onLeaveHoverTarget}
				onFocus={() =>
					onEnterHoverTarget({
						itemID: item.id.toString(),
						isQuickActionsFocused,
						hoverPageCardTriggerRef,
					})
				}
			>
				<MenuLinkItem
					testId="space-views-list-item"
					onClick={onClick}
					href={url}
					isSelected={isSelected}
					elemBefore={
						<Box xcss={dotIconContainerNav4Styles} testId="dot-icon">
							<Box xcss={dotIconStyles}>&bull;</Box>
						</Box>
					}
				>
					<Grid alignItems="center" columnGap="space.075" templateColumns="minmax(0, auto) 1fr">
						<EmojiOrContentIcon
							isContentTreeIconVisible={isContentTreeIconVisible}
							contentType={contentType}
							iconColor={iconColor}
							displayEmoji={displayEmoji}
							isNav4Enabled
						/>
						<Box as="span" xcss={titleStyles}>
							{renderTitle({ title })}
						</Box>
					</Grid>
				</MenuLinkItem>
			</div>
		);
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			ref={hoverPageCardTriggerRef}
			onBlur={onBlurHoverTarget}
			onMouseEnter={() =>
				onEnterHoverTarget({
					itemID: item.id.toString(),
					isQuickActionsFocused,
					hoverPageCardTriggerRef,
				})
			}
			onMouseLeave={onLeaveHoverTarget}
			onFocus={() =>
				onEnterHoverTarget({
					itemID: item.id.toString(),
					isQuickActionsFocused,
					hoverPageCardTriggerRef,
				})
			}
		>
			<ConditionalInlineRename
				id={item.id}
				displayEmoji={displayEmoji}
				title={title}
				isEditingTitle={isEditingTitleId === item.id}
				setIsEditingTitleId={setIsEditingTitleId}
				setFocusedQuickActionsId={setFocusedQuickActionsId}
				updateContentTreeItem={noop}
			>
				<ConditionalTreeItemTooltip
					title={title}
					isHoverPageCardOptedIn={isHoverPageCardOptedIn}
					isQuickActionsOpen={isQuickActionsOpen}
				>
					<LinkItem
						testId="space-views-list-item"
						// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis, @atlaskit/design-system/no-unsafe-style-overrides
						cssFn={CSSFn}
						onClick={onClick}
						// @ts-ignore
						href={url}
						isSelected={isSelected}
						iconAfter={
							shouldRenderAfterIcon && (
								<PageTreeAfterIcon
									setFocusedQuickActionsId={setFocusedQuickActionsId}
									isSuperAdmin={isSuperAdmin}
									isQuickActionsOpen={isQuickActionsOpen}
									setOpenQuickActionsId={setOpenQuickActionsId}
									setIsEditingTitleId={setIsEditingTitleId}
									closeAllHoverPageCards={closeAllHoverPageCards}
									spaceKey={spaceKey}
									item={item}
									displayTitle={title}
									hideFolder
								/>
							)
						}
						iconBefore={
							<>
								<Box xcss={dotIconContainerStyles} testId="dot-icon">
									<Box xcss={dotIconStyles}>&bull;</Box>
								</Box>
								<EmojiOrContentIcon
									isContentTreeIconVisible={isContentTreeIconVisible}
									contentType={contentType}
									iconColor={iconColor}
									displayEmoji={displayEmoji}
								/>
							</>
						}
					>
						{renderTitle({ title })}
					</LinkItem>
				</ConditionalTreeItemTooltip>
			</ConditionalInlineRename>
		</div>
	);
};

const noop = () => {};

const dotIconContainerStyles = xcss({
	width: '24px',
	font: 'font.body.large',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginRight: 'space.100',
});

const dotIconContainerNav4Styles = xcss({
	width: '15px',
	marginLeft: 'space.100',
	font: 'font.body.large',
	color: 'color.text.subtlest',
});

const dotIconStyles = xcss({
	height: '3px',
	lineHeight: '0',
});

const titleStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const contentIconWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingRight: 'space.075',
});

const contentIconWrapperNav4Styles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexShrink: 0,
});
