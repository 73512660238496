import React from 'react';

import { token } from '@atlaskit/tokens';
import GridIconCore from '@atlaskit/icon/core/grid';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

export const GridIcon = ({ isSelected }: { isSelected: boolean }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<GridIconCore
			spacing="spacious"
			color={isSelected ? token('color.icon.selected', '#0c66e4') : token('color.icon', '#42526E')}
			label=""
			{...ssrPlaceholderIdProp}
			LEGACY_fallbackIcon={() => (
				<svg
					width="25"
					height="25"
					viewBox="0 0 25 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					data-vc="grid-icon"
					{...ssrPlaceholderIdProp}
				>
					<path
						d="M9.02344 13.2999C10.0778 13.2999 10.9416 14.1157 11.018 15.1506L11.0234 15.2999V18.2999C11.0234 19.3542 10.2076 20.218 9.1727 20.2944L9.02344 20.2999H6.02344C4.96908 20.2999 4.10527 19.484 4.02892 18.4492L4.02344 18.2999V15.2999C4.02344 14.2455 4.83932 13.3817 5.87418 13.3054L6.02344 13.2999H9.02344ZM18.0234 13.2999C19.0778 13.2999 19.9416 14.1157 20.018 15.1506L20.0234 15.2999V18.2999C20.0234 19.3542 19.2076 20.218 18.1727 20.2944L18.0234 20.2999H15.0234C13.9691 20.2999 13.1053 19.484 13.0289 18.4492L13.0234 18.2999V15.2999C13.0234 14.2455 13.8393 13.3817 14.8742 13.3054L15.0234 13.2999H18.0234ZM9.02344 15.2999H6.02344V18.2999H9.02344V15.2999ZM18.0234 15.2999H15.0234V18.2999H18.0234V15.2999ZM18.0234 4.29987C19.0778 4.29987 19.9416 5.11571 20.018 6.15057L20.0234 6.29987V9.29987C20.0234 10.3542 19.2076 11.218 18.1727 11.2944L18.0234 11.2999H15.0234C13.9691 11.2999 13.1053 10.484 13.0289 9.44916L13.0234 9.29987V6.29987C13.0234 5.24551 13.8393 4.3817 14.8742 4.30536L15.0234 4.29987H18.0234ZM9.02344 4.29987C10.0778 4.29987 10.9416 5.11571 11.018 6.15057L11.0234 6.29987V9.29987C11.0234 10.3542 10.2076 11.218 9.1727 11.2944L9.02344 11.2999H6.02344C4.96908 11.2999 4.10527 10.484 4.02892 9.44916L4.02344 9.29987V6.29987C4.02344 5.24551 4.83932 4.3817 5.87418 4.30536L6.02344 4.29987H9.02344ZM18.0234 6.29987H15.0234V9.29987H18.0234V6.29987ZM9.02344 6.29987H6.02344V9.29987H9.02344V6.29987Z"
						fill={
							isSelected ? token('color.icon.selected', '#0c66e4') : token('color.icon', '#42526E')
						}
					/>
				</svg>
			)}
		/>
	);
};
