import { useCallback } from 'react';

export {
	useCommentsData,
	CommentsDataStore,
	type CommentData,
	type ReplyData,
	type AnnotationStatus,
	UnreadAction,
	CommentActionType,
} from './useCommentsData';
export {
	useActiveInlineCommentsQuery,
	COMMENT_BATCH_SIZE,
} from './hooks/useActiveInlineCommentsQuery';
export { updateCommentsDataState } from './helper/commentsDataHelper';

export const useUpdateAnnotations = (callbacks: ((annotations: string[]) => void)[]) =>
	useCallback((annotationsList: string[]) => {
		callbacks.forEach((callback) => callback(annotationsList));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, callbacks);
