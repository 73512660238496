import React, { useState } from 'react';

import { useIntl } from 'react-intl-next';

import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { useThemeObserver } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { ClassificationView } from '../..//common/ui/classification-view';
import { colorsConfig, disabledColorConfig, nameMaxLength } from '../../common/constants';
import { ClassificationIcon } from '../../common/ui/classification-icon';
import { messages } from '../../messages';

import { ContainerButton, ContainerSpan, Name } from './styled';
import { type DataClassificationLevelProps } from './types';

const classificationViewContainerStyles = xcss({
	width: '330px',
});

export default function DataClassificationLevel({
	testId,
	name,
	tooltip,
	tooltipPosition,
	color = 'GREY',
	iconSize = 'small',
	guideline,
	isDisabled = false,
	isPopupDisabled = false,
	isPopupOpenByDefault = false,
	getPopupStateControllers,
	popupZIndex,
	guidelineRepresentation = 'PLAIN',
	onPopupShown,
	onPopupClose,
	onClick,
	renderPopupComponent,
	popupLabel,
	popupTitleId,
	popupRole,
	popupPlacement = 'bottom-start',
	shouldRenderToParent,
	isIconOnly: isIconOnlyProp = false,
	associatedPoliciesCount,
}: DataClassificationLevelProps) {
	const { formatMessage } = useIntl();
	const { colorMode = 'light' } = useThemeObserver();
	const [isPopupOpen, setIsPopupOpen] = useState(
		isDisabled || isPopupDisabled ? false : isPopupOpenByDefault,
	);
	if (getPopupStateControllers) {
		const getOpenState = () => isPopupOpen;
		const setOpenState = (isOpen: boolean) => setIsPopupOpen(isOpen);
		getPopupStateControllers(getOpenState, setOpenState);
	}

	const isMissingName = !name || name.length === 0;
	const isIconOnly = isMissingName || isIconOnlyProp;

	const nameWithRules = isMissingName
		? ''
		: associatedPoliciesCount
			? name + ' ' + formatMessage(messages.rules, { associatedPoliciesCount })
			: name;
	const nameTruncated = (nameWithRules ?? '').substring(0, nameMaxLength);

	const iconPx: number =
		iconSize === 'small'
			? 14
			: iconSize === 'medium'
				? 18
				: iconSize === 'large'
					? 24
					: iconSize === 'xlarge'
						? 32
						: iconSize === 'xxlarge'
							? 40
							: 14;

	const handleContainerClicked = (): void => {
		if (isPopupDisabled || isDisabled || isMissingName) {
			return;
		}

		if (onPopupShown) {
			onPopupShown();
		}

		setIsPopupOpen(!isPopupOpen);
	};

	const closePopup = () => {
		setIsPopupOpen(false);
	};

	const containerTagType = isPopupDisabled || isMissingName ? 'span' : 'button';
	const ContainerTag = containerTagType === 'span' ? ContainerSpan : ContainerButton;

	const getContainer = (triggerProps = {}) => {
		// @ts-ignore
		const { 'aria-expanded': ariaExpanded, ...restProps } = triggerProps;
		return (
			<ContainerTag
				data-testid={testId}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={isDisabled ? 'disabled' : ''}
				disabledColorConfig={disabledColorConfig[colorMode]}
				colorsConfig={colorsConfig[colorMode][color]}
				isIconOnly={isIconOnly}
				isPopupDisabled={isPopupDisabled}
				{...(containerTagType === 'button' ? triggerProps : restProps)}
				{...(containerTagType === 'button' ? { disabled: isDisabled, type: 'button' } : {})}
				onClick={() => {
					if (onClick) {
						onClick();
					}
					handleContainerClicked();
				}}
			>
				<ClassificationIcon
					width={iconPx}
					height={iconPx}
					isDisabled={isDisabled}
					color={colorsConfig[colorMode][color].fontColor}
					disabledColorConfig={disabledColorConfig[colorMode]}
				/>
				{!isIconOnly && <Name data-testid="data-classification-level-name">{nameTruncated}</Name>}
			</ContainerTag>
		);
	};

	return isPopupDisabled ? (
		getContainer()
	) : (
		<Popup
			label={popupLabel}
			titleId={popupTitleId}
			role={popupRole}
			shouldRenderToParent={shouldRenderToParent}
			placement={popupPlacement}
			trigger={(triggerProps) => (
				<Tooltip
					content={isPopupOpen ? null : tooltip}
					hideTooltipOnClick
					position={tooltipPosition}
				>
					{getContainer(triggerProps)}
				</Tooltip>
			)}
			content={() =>
				renderPopupComponent ? (
					renderPopupComponent(closePopup)
				) : (
					<Box
						padding="space.250"
						xcss={classificationViewContainerStyles}
						role="dialog"
						aria-labelledby="data-classification-read-content-name"
						aria-describedby="data-classification-read-content-guideline"
						aria-modal="true"
					>
						<ClassificationView
							name={name}
							guideline={guideline}
							guidelineRepresentation={guidelineRepresentation}
						/>
					</Box>
				)
			}
			isOpen={isPopupOpen}
			onClose={() => {
				if (onPopupClose) {
					onPopupClose();
				}
				closePopup();
			}}
			zIndex={popupZIndex}
		/>
	);
}
