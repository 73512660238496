import type { FC } from 'react';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { Emoji, ResourcedEmoji } from '@atlaskit/emoji/element';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import DocumentIcon from '@atlaskit/icon-file-type/glyph/document/16';
import BlogIcon from '@atlaskit/icon-object/glyph/blog/16';
import LinkIcon from '@atlaskit/icon/core/link';
import type { IconColor, IconColorPressed } from '@atlaskit/tokens/css-type-schema';

import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { getOptimisticImageURL, getEmojiSSRSupport } from '@confluence/fabric-emoji-support';
import { useSessionData } from '@confluence/session-data';
import { ErrorDisplay } from '@confluence/error-boundary';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { onFailedToLoadEmoji } from './analytics';
import type { EmojiComponentProps } from './__types__/EmojiComponent';
import { emojiExp, maybeOverrideFaviconWithEmoji } from './emojiMutationHelpers';
import { isValidUrl } from './isValidUrl';
import { getEmojiProvider, sanitizeEmojiValue } from './transformer';
import { EmojiPlaceholder } from './EmojiPlaceholder';
import { useEmojiDescription } from './useEmojiDescription';
import { EMOJI_SIZE_MEDIUM, EMOJI_SIZE_LARGE } from './emojiTitleConstants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiWrapper = styled.span<{
	hasCoverPicture: boolean;
	isPageTitleComponent?: boolean;
	shouldRemoveEmojiLeftPadding?: boolean;
}>({
	display: 'flex',
	justifyContent: 'center',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ hasCoverPicture, isPageTitleComponent }) =>
		isPageTitleComponent
			? hasCoverPicture
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					`${EMOJI_SIZE_LARGE}px`
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					`${EMOJI_SIZE_MEDIUM}px`
			: '28px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ hasCoverPicture, isPageTitleComponent }) =>
		isPageTitleComponent
			? hasCoverPicture
				? token('space.250', '20px')
				: token('space.100', '8px')
			: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ hasCoverPicture, isPageTitleComponent, shouldRemoveEmojiLeftPadding }) =>
		shouldRemoveEmojiLeftPadding
			? token('space.0', '0px')
			: isPageTitleComponent
				? hasCoverPicture
					? token('space.250', '20px')
					: token('space.100', '8px')
				: token('space.075', '6px'),
	paddingTop: token('space.025', '2px'),
	paddingBottom: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: ({ isPageTitleComponent }) => (isPageTitleComponent ? '40px' : '26px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	lineHeight: ({ isPageTitleComponent }) => (isPageTitleComponent ? '40px' : '26px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isPageTitleComponent }) => (isPageTitleComponent ? 'pointer' : 'initial'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResourcedEmojiWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		display: 'flex',
	},
});

const EmbedImage: FC<{
	emoji: string;
	height: number;
	primaryColor?: 'currentColor' | IconColor | IconColorPressed;
}> = ({ emoji, height, primaryColor }) => {
	const intl = useIntl();
	const [hasImageError, setHasImageError] = useState(false);

	const handleImageError = useCallback(() => {
		setHasImageError(true);
	}, []);

	return hasImageError ? (
		<LinkIcon color={primaryColor} label={intl.formatMessage(i18n.embed)} testId="embed-svg" />
	) : (
		<img src={emoji} height={height} alt="" onError={handleImageError} />
	);
};

const EmojiComponent: FC<EmojiComponentProps> = ({
	emoji,
	primaryColor,
	context,
	height = 26,
	contentType = 'page',
	showTooltip = false,
	wrapper,
	loadingMargin,
	renderResourcedEmoji = false,
	hasCoverPicture,
	isPageTitleComponent = false,
	shouldRemoveEmojiLeftPadding = false,
}) => {
	const intl = useIntl();
	const { userId, cloudId, isLoggedIn, isLicensed } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isLivePage = useIsCurrentPageLive();
	const [{ emojiDescription, error }, { loadEmojiDescription }] = useEmojiDescription(emoji);
	const emojiId =
		typeof emoji === 'string'
			? sanitizeEmojiValue(emoji)
			: emoji && typeof emoji === 'object'
				? sanitizeEmojiValue(emoji.id)
				: null;
	const isSSR = !!process.env.REACT_SSR || !!window.__SSR_RENDERED__ || renderResourcedEmoji;
	const isGuestUser = !isLicensed || !isLoggedIn;
	const isEmojiSSRSupported = useMemo(
		() =>
			getEmojiSSRSupport({
				isSSR,
				hasCloudId: !!cloudId,
				isGuestUser,
			}),
		[isSSR, cloudId, isGuestUser],
	);
	const shouldRenderResourcedEmoji = !!emojiId && !!userId && isEmojiSSRSupported;
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();

	useEffect(() => {
		if (emojiId && isPageTitleComponent) {
			const url = getOptimisticImageURL(cloudId, emojiId);
			if (url) {
				maybeOverrideFaviconWithEmoji(url);
			}
		}

		return () => {
			if (isPageTitleComponent) {
				maybeOverrideFaviconWithEmoji(null);
			}
		};
	}, [isPageTitleComponent, emojiId, cloudId]);

	useEffect(() => {
		// EmojiComponent accept both the EmojiDescription object and an emoji id string
		// if user pass in emoji id string, has to first fetch the emoji icon using emojiProvider
		// to pass into <Emoji /> below
		if (
			!shouldRenderResourcedEmoji &&
			emojiId && // the emoji prop is a string, not an EmojiDescription
			!emojiDescription // emojiDescription has not been loaded yet
		) {
			loadEmojiDescription({
				emojiId,
				userId: userId!,
				cloudId,
			});
		}
	}, [
		loadEmojiDescription,
		emojiId,
		emojiDescription,
		cloudId,
		userId,
		shouldRenderResourcedEmoji,
	]);

	const Wrapper = wrapper || EmojiWrapper;

	/**
	 * Why do emojis need to be 'sanitized'? Originally emoji content properties
	 * (emoji-title-published, emoji-title-draft) were a part of a REST API
	 * where the value would be JSON and not just a string.  This is why emoji
	 * values come through with extra quotes and we have to clean that up.
	 * A URL with quotes is not a valid URL.
	 * e.g. '"https://www.youtube.com/s/desktop/1f2ae858/img/favicon_32x32.png"' vs.
	 * "https://www.youtube.com/s/desktop/1f2ae858/img/favicon_32x32.png"
	 */

	// If emoji is a URL, render it as an image.  Setting height on
	// the img via the prop passed in ensures it renders well across
	// various places (home cards, recents in top nav, etc.).
	if (typeof emoji === 'string') {
		const santiziedEmoji = sanitizeEmojiValue(emoji);
		if (isValidUrl(santiziedEmoji)) {
			return (
				<Wrapper
					data-testid="emoji-wrapper"
					hasCoverPicture={Boolean(hasCoverPicture)}
					isPageTitleComponent={isPageTitleComponent}
					shouldRemoveEmojiLeftPadding={shouldRemoveEmojiLeftPadding}
					data-vc="emoji-component"
					{...ssrPlaceholderIdProp}
				>
					<EmbedImage emoji={santiziedEmoji} height={height} primaryColor={primaryColor} />
				</Wrapper>
			);
		}
	}

	// render a default image if emoji is not yet set for the embed content type
	if (contentType === 'embed' && !emoji) {
		return (
			<Wrapper
				data-testid="emoji-wrapper"
				hasCoverPicture={Boolean(hasCoverPicture)}
				isPageTitleComponent={isPageTitleComponent}
				shouldRemoveEmojiLeftPadding={shouldRemoveEmojiLeftPadding}
				data-vc="emoji-component"
				{...ssrPlaceholderIdProp}
			>
				<LinkIcon color={primaryColor} label={intl.formatMessage(i18n.embed)} testId="embed-svg" />
			</Wrapper>
		);
	}

	// if emoji is unicode, render it directly
	if (typeof emoji === 'string' && emojiExp.test(emoji)) {
		return (
			<Wrapper
				data-testid="emoji-wrapper"
				hasCoverPicture={!!hasCoverPicture}
				isPageTitleComponent={isPageTitleComponent}
				shouldRemoveEmojiLeftPadding={shouldRemoveEmojiLeftPadding}
				data-vc="emoji-component"
				{...ssrPlaceholderIdProp}
			>
				{emoji}
			</Wrapper>
		);
	}

	// emoji not yet set and still loading
	if (!shouldRenderResourcedEmoji) {
		if (emojiDescription === undefined) {
			return <EmojiPlaceholder height={height} loadingMargin={loadingMargin} />;
		} else if (emojiDescription === null) {
			if (context !== 'smartCard') return null;
			// for the case of smart link/card
			// emoji not found, might be from another space or site
			// in this case return a default document/blogpost to avoid showing placeholder indefinitely
			return contentType === 'blogpost' ? (
				<BlogIcon label={intl.formatMessage(i18n.blogpost)} testId="blog-icon" />
			) : (
				<DocumentIcon label={intl.formatMessage(i18n.document)} testId="document-icon" />
			);
		}
	}

	let emojiComponent = null;
	if (shouldRenderResourcedEmoji) {
		emojiComponent = (
			<ResourcedEmojiWrapper>
				<ResourcedEmoji
					emojiId={{
						id: emojiId,
						shortName: emojiId,
					}}
					emojiProvider={getEmojiProvider(cloudId, userId)}
					showTooltip={showTooltip}
					fitToHeight={height}
					optimisticImageURL={getOptimisticImageURL(cloudId, emojiId)}
					customFallback={<EmojiPlaceholder height={height} loadingMargin={loadingMargin} />}
				/>
			</ResourcedEmojiWrapper>
		);
	} else if (emojiDescription) {
		emojiComponent = (
			<Emoji
				emoji={emojiDescription}
				fitToHeight={height}
				showTooltip={showTooltip}
				// @ts-ignore
				onLoadError={() => {
					// Throw an exception here if you want to see who renders this emoji.
					// eslint-disable-next-line no-console
					console.error(`Failed to load emoji`, emoji);

					// NOTE: We are currently in the process of asking for UGC exception for shortName
					// shortName (which comes from a user entered field when uploading a custom emoji).
					// However, if this request is rejected we will have to update this analytic to exclude
					// emoji.
					onFailedToLoadEmoji({
						createAnalyticsEvent,
						emoji: emojiDescription,
						context,
						isLivePage,
					});

					// some emoji (especially custom emoji) failed to load the first time user add/publish page
					// when that happens, attempt to refetch and get emoji once
					if (emojiId && userId) {
						loadEmojiDescription({
							emojiId,
							userId,
							cloudId,
						});
					}
				}}
			/>
		);
	}

	return (
		<Wrapper
			data-testid="emoji-wrapper"
			hasCoverPicture={!!hasCoverPicture}
			isPageTitleComponent={isPageTitleComponent}
			shouldRemoveEmojiLeftPadding={shouldRemoveEmojiLeftPadding}
			data-vc="emoji-component"
			{...ssrPlaceholderIdProp}
		>
			{emojiComponent}
			{error && <ErrorDisplay error={error} />}
		</Wrapper>
	);
};

EmojiComponent.displayName = 'EmojiComponent';

export { EmojiComponent };

const i18n = defineMessages({
	blogpost: {
		id: 'emoji-title.icon.blogpost',
		defaultMessage: 'Blog',
		description: "Label for the 'blog' content type icon",
	},
	embed: {
		id: 'emoji-title.icon.embed',
		defaultMessage: 'Embed',
		description: "Label for the 'embed' content type icon",
	},
	document: {
		id: 'emoji-title.icon.document',
		defaultMessage: 'Document',
		description: "Label for the 'document' content type icon",
	},
});
