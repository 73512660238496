/** @jsx jsx */
import type { FC } from 'react';
import React, { memo, useEffect, useCallback, useState, useRef } from 'react';
import { styled, css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { R400, B400, N20, N70 } from '@atlaskit/theme/colors';
import AkSpinner from '@atlaskit/spinner/spinner';
import AkChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import AkChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';

import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import { CONTEXT_PATH } from '@confluence/named-routes';
import type { RenderItemParams } from '@confluence/tree';
import { ContentTreeIconLoader as ContentTreeIcon } from '@confluence/icons/entry-points/ContentTreeIcon';
import { includesValidContentTreeType } from '@confluence/icons/entry-points/contentTreeTypes';
import type { ContentTreeTypes } from '@confluence/icons/entry-points/contentTreeTypes';
import { getURLBypassingResumeDraftAction } from '@confluence/content-utils';
import { PAGE_TREE_ITEM_CLICK, setViewTransitionSource } from '@confluence/browser-metrics';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { fg } from '@confluence/feature-gating';
import { useOptimisticTitleChange } from '@confluence/page-tree-refresh-state-container/entry-points/useOptimisticTitleChange';

import { ANIMATE_TIME_SEC } from './index';

import type { ContentTreeItem } from './data-transformers';
import { PageTreeAfterIconLoader as PageTreeAfterIcon } from './PageTreeAfterIconLoader';
import { ConditionalTreeItemTooltip } from './ConditionalTreeItemTooltip';
import { ConditionalInlineRename } from './quick-actions';
import { HomepageIcon } from './HomepageIconLoader';
import { DraftLozenge } from './DraftLozengeLoader';
import { PageTreeLinkItem } from './PageTreeLinkItem';

// Checks if the content type supports emojis and if an emoji is set
const useIsEmojiSetForType = (contentType: string | undefined, emoji: any): boolean => {
	// Add other checks for other content types and corresponding feature flags here as they get implemented.
	if (contentType === 'whiteboard') {
		return !!emoji && fg('confluence_frontend_whiteboard_emoji_titles');
	}

	if (contentType === 'page') {
		return !!emoji;
	}

	return false;
};

const SPINNER_SIZE_MEDIUM = 16;
const CHEVRON_SIZE = 'medium';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DotIconContainer = styled.div<{ itemIsRestricted: boolean }>({
	width: '24px',
	font: token('font.body.large'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => (props.itemIsRestricted ? token('color.icon.danger', R400) : 'inherit'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DotIcon = styled.div({
	height: '3px',
	lineHeight: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.span({
	display: 'inline-block',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageTreeItemSpinner = styled.div({
	margin: `0 ${token('space.050', '4px')}`,
});

const contentIconWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingRight: 'space.100',
});

const contentIconWrapperNav4Styles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: 'space.025',
	marginLeft: 'space.025',
	width: '24px',
	height: '24px',
	flexShrink: 0,
});

/**
 * 1. Emojis are rendered as images, and img has draggable=true set by default, which interferes with the DraggableTreeItem.
 * 2. Also, the Emoji component calls preventDefault on crucial events like onMouseDown, which also breaks pragmatic-dnd.
 * Since we don't need emojis to be interactive for this use case, we can just disable pointer-events on them entirely.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmojiWrapper = styled.span({
	pointerEvents: 'none',
	display: 'flex',
	flexShrink: 0,
	alignItems: 'center',
	marginRight: token('space.100', '8px'),
	height: '16px',
	width: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'img, svg': {
		height: '16px',
		width: '16px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const Nav4EmojiWrapper = styled.span({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginInline: token('space.075', '6px'),
	marginBlock: token('space.050', '4px'),
	height: '16px',
	width: '16px',
	overflow: 'hidden',
	flexShrink: 0,
});

export type BeforeIconProps = {
	item: ContentTreeItem;
	isSuperAdmin?: boolean;
	onExpand(e: React.MouseEvent): void;
	onCollapse(e: React.MouseEvent): void;
};

const beforeIconStyles = css({
	zIndex: 1,
	display: 'flex',
	border: 'none',
	margin: 0,
	padding: 0,
	textDecoration: 'none',
	backgroundColor: 'transparent',
	color: 'inherit',
	cursor: 'pointer',
});

const ssrStyles = css({
	cursor: 'wait',
});

const beforeIconStylesNav4Styles = css({
	height: token('space.300', '24px'),
	width: token('space.300', '24px'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '3px',
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered', N20),
	},
});

export const BeforeIcon: FC<BeforeIconProps> = memo(
	({ item, isSuperAdmin, onExpand, onCollapse }) => {
		const isNav4Enabled = useIsNav4Enabled();
		const {
			isChildrenLoading,
			hasChildren,
			isExpanded,
			hasRestrictions,
			hasInheritedRestrictions,
		} = item;

		const shouldDisplayRed = Boolean(
			!!isSuperAdmin && (hasRestrictions || hasInheritedRestrictions),
		);

		const iconColor = shouldDisplayRed ? token('color.icon.danger', R400) : undefined;

		if (isChildrenLoading) {
			return (
				<PageTreeItemSpinner data-testid="tree-item-spinner">
					<AkSpinner size={SPINNER_SIZE_MEDIUM} />
				</PageTreeItemSpinner>
			);
		}

		if (hasChildren || item.data.type === 'folder') {
			return isExpanded ? (
				<button
					onClick={onCollapse}
					data-testid="chevron-down"
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					css={[
						beforeIconStyles,
						process.env.REACT_SSR && ssrStyles,
						isNav4Enabled && beforeIconStylesNav4Styles,
					]}
					aria-expanded={isExpanded}
					aria-labelledby={item.id}
				>
					{isNav4Enabled ? (
						<ChevronDownIcon color={iconColor} label="" />
					) : (
						<AkChevronDownIcon color={iconColor} label="" LEGACY_size={CHEVRON_SIZE} />
					)}
				</button>
			) : (
				<button
					onClick={onExpand}
					data-testid="chevron-right"
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					css={[
						beforeIconStyles,
						process.env.REACT_SSR && ssrStyles,
						isNav4Enabled && beforeIconStylesNav4Styles,
					]}
					aria-expanded={isExpanded}
					aria-labelledby={item.id}
				>
					{isNav4Enabled ? (
						<ChevronRightIcon color={iconColor} label="" />
					) : (
						<AkChevronRightIcon color={iconColor} label="" LEGACY_size={CHEVRON_SIZE} />
					)}
				</button>
			);
		}

		return (
			<DotIconContainer itemIsRestricted={shouldDisplayRed} data-testid="dot-icon">
				<DotIcon>&bull;</DotIcon>
			</DotIconContainer>
		);
	},
);

export const PageTreeItemContent: FC<{
	id: string;
	displayHomePageIcon: boolean;
	displayEmoji: any;
	displayTitle: string;
	isDraft: boolean;
	renderResourcedEmoji?: boolean;
	contentType?: string;
	isSelected?: boolean;
	draggableState?: string;
	emoji?: React.ReactNode;
	isExpanded?: boolean;
}> = memo(
	({
		id,
		displayHomePageIcon,
		displayEmoji,
		displayTitle,
		isDraft,
		renderResourcedEmoji,
		contentType,
		isSelected,
		draggableState,
		emoji,
		isExpanded,
	}) => {
		const isNav4Enabled = useIsNav4Enabled();
		const iconColor = isSelected ? token('color.icon.selected', B400) : undefined;
		const draggingIconColor = token('color.icon.disabled', N70);
		const isDragging = draggableState === 'dragging';
		const isEmojiSet = useIsEmojiSetForType(contentType, emoji);
		const isContentTreeIconVisible =
			contentType && includesValidContentTreeType(contentType, isNav4Enabled) && !isEmojiSet;
		const isEmojiOptimized = !!emoji;
		const isEmbedWithoutEmoji = contentType === 'embed' && !displayEmoji;
		const iconPrimaryColor = isDragging ? draggingIconColor : iconColor;

		return (
			<Inline as="span" alignBlock="center">
				{displayHomePageIcon ? <HomepageIcon /> : null}
				{isContentTreeIconVisible && (
					<Box
						as="span"
						xcss={[isNav4Enabled ? contentIconWrapperNav4Styles : contentIconWrapperStyles]}
					>
						<ContentTreeIcon
							type={contentType as ContentTreeTypes}
							label=""
							color={iconPrimaryColor}
							isExpanded={isExpanded}
						/>
					</Box>
				)}
				{(displayEmoji || isEmbedWithoutEmoji) &&
					(isEmojiOptimized ? (
						emoji
					) : (
						<LazyEmojiComponentLoader
							key={displayEmoji}
							emoji={displayEmoji}
							contentType={contentType}
							context="pageTree"
							height={14}
							primaryColor={iconPrimaryColor}
							wrapper={isNav4Enabled ? Nav4EmojiWrapper : EmojiWrapper}
							renderResourcedEmoji={renderResourcedEmoji}
						/>
					))}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<TitleWrapper id={id} className="title">
					{isNav4Enabled ? (
						<Text weight="medium" color={isSelected ? 'color.text.selected' : 'color.text.subtle'}>
							{displayTitle}
						</Text>
					) : (
						displayTitle
					)}
				</TitleWrapper>
				{isDraft && <DraftLozenge />}
			</Inline>
		);
	},
);

export type PageTreeItemProps = {
	item: ContentTreeItem;
	draftText: string;
	spaceHomePageId?: string;
	onHighlight(): void;
	isSuperAdmin: boolean;
	onClick(event: any): void;
	isQuickActionsEnabled: boolean;
	isInlineRenameEnabled: boolean;
	isDraft: boolean;
	spaceKey: string;
	emoji?: React.ReactNode;
	isHoverPageCardOptedIn: boolean;
	closeAllHoverPageCards: () => void;
	isQuickActionsOpen: boolean;
	setOpenQuickActionsId: (id: string | null) => void;
	isQuickActionsFocused: boolean;
	setFocusedQuickActionsId: (focusedQuickActionsId: string | null) => void;
	editingTitle: { contentId: string | null; isNewContent?: boolean } | null;
	setIsEditingTitleId: (isEditingTitleId: string | null) => void;
	onEnterHoverTarget: (params: {
		itemID: string;
		isQuickActionsFocused: boolean;
		hoverPageCardTriggerRef: React.RefObject<HTMLDivElement>;
		isContextualCreateFocused: boolean;
	}) => void;
	onLeaveHoverTarget: () => void;
	onBlurHoverTarget: (e) => void;
	updateSinglePage: (id: string, data: object) => void;
	shouldRenderAfterIcon?: boolean;
	contentStatus?: string;
	renderResourcedEmoji?: boolean;
	hideDraftHrefs?: boolean;
} & RenderItemParams<ContentTreeItem>;

export const PageTreeItem: FC<PageTreeItemProps> = ({
	item,
	draftText = '',
	spaceHomePageId,
	onExpand,
	onCollapse,
	onHighlight,
	draggableState,
	isSuperAdmin,
	onClick,
	spaceKey,
	indent,
	dragHandleProps,
	isHoverPageCardOptedIn,
	closeAllHoverPageCards,
	isQuickActionsOpen,
	setOpenQuickActionsId,
	isQuickActionsFocused,
	setFocusedQuickActionsId,
	editingTitle,
	setIsEditingTitleId,
	onEnterHoverTarget,
	onLeaveHoverTarget,
	onBlurHoverTarget,
	isDraft,
	updateSinglePage,
	shouldRenderAfterIcon,
	contentStatus,
	renderResourcedEmoji,
	hideDraftHrefs,
	emoji,
}) => {
	const isNav4Enabled = useIsNav4Enabled();
	const hoverPageCardTriggerRef = useRef<HTMLDivElement>(null);
	const displayEmoji = item.data.emoji;

	const onOptimisticUpdate = useCallback(
		(_, title: string) => {
			updateSinglePage(item.id, {
				title,
				fullTitle: title,
			});
		},
		[updateSinglePage, item.id],
	);

	const defaultTitle = item.data.title || draftText;
	const { optimisticTitle } = useOptimisticTitleChange(item.id, defaultTitle, onOptimisticUpdate);
	const displayTitle = optimisticTitle || draftText;

	const contentType = item.data.type;
	const isHighlighted = item.data.isHighlighted;
	const selected = item.data.isSelected;

	// For drafts, webui can be a link to `resumedraft.action`, so this bypass is used to speed up navigation in those cases.
	const bypassHref =
		isDraft && hideDraftHrefs
			? null
			: getURLBypassingResumeDraftAction({
					url: `${CONTEXT_PATH}${item.data.webui}`,
					spaceKey,
					contentType: item.data.type,
					editorVersion: item.data.editorVersion as any, //TODO: fix this type
					contentId: item.id,
				});

	useEffect(() => {
		// if this is a highlighted page, let parent know when it's finished being
		// visually highlighted (on mount + animation duration)
		if (isHighlighted) {
			setTimeout(onHighlight, ANIMATE_TIME_SEC * 1000);
		}
	}, [isHighlighted, onHighlight]);

	const [isContextualCreateFocused, setIsContextualCreateFocused] = useState<boolean>(false);

	return (
		<div
			onMouseDown={() => {
				if (contentType === 'page' && !isDraft) {
					setViewTransitionSource(PAGE_TREE_ITEM_CLICK);
				}
				closeAllHoverPageCards();
				isQuickActionsOpen && setOpenQuickActionsId(null);
			}}
			onBlur={onBlurHoverTarget}
			ref={hoverPageCardTriggerRef}
			data-contentid={item.id}
			role="presentation"
		>
			<ConditionalInlineRename
				id={item.id}
				title={item.data.fullTitle || ''}
				displayEmoji={item.data.emojiFromProperties || ''}
				isEditingTitle={editingTitle?.contentId === item.id}
				isNewContent={editingTitle?.isNewContent}
				setIsEditingTitleId={setIsEditingTitleId}
				setFocusedQuickActionsId={setFocusedQuickActionsId}
				updateContentTreeItem={updateSinglePage}
				contentStatus={contentStatus}
				contentType={contentType}
				indent={indent}
			>
				<ConditionalTreeItemTooltip
					title={displayTitle}
					isHoverPageCardOptedIn={isHoverPageCardOptedIn}
					isQuickActionsOpen={isQuickActionsOpen}
				>
					<PageTreeLinkItem
						isNav4Enabled={isNav4Enabled}
						dragHandleProps={dragHandleProps}
						onMouseEnter={() => {
							onEnterHoverTarget({
								itemID: item.id.toString(),
								isQuickActionsFocused,
								hoverPageCardTriggerRef,
								isContextualCreateFocused,
							});
						}}
						onFocus={() =>
							onEnterHoverTarget({
								itemID: item.id.toString(),
								isQuickActionsFocused,
								hoverPageCardTriggerRef,
								isContextualCreateFocused,
							})
						}
						onMouseLeave={onLeaveHoverTarget}
						onBlur={onBlurHoverTarget}
						isSelected={Boolean(selected)}
						onClick={onClick}
						href={bypassHref}
						item={item}
						draggableState={draggableState}
						isSuperAdmin={isSuperAdmin}
						indent={indent}
						forceHoverBackground={isQuickActionsOpen || isContextualCreateFocused}
						isQuickActionsFocused={isQuickActionsFocused}
						beforeIcon={
							<BeforeIcon
								item={item}
								isSuperAdmin={isSuperAdmin}
								onExpand={(event) => {
									event.preventDefault();
									event.stopPropagation();
									onExpand?.(item.id);
								}}
								onCollapse={(event) => {
									event.preventDefault();
									event.stopPropagation();
									onCollapse?.(item.id);
								}}
							/>
						}
						afterIcon={
							shouldRenderAfterIcon && (
								<PageTreeAfterIcon
									setFocusedQuickActionsId={setFocusedQuickActionsId}
									isSuperAdmin={isSuperAdmin}
									isQuickActionsOpen={isQuickActionsOpen}
									setOpenQuickActionsId={setOpenQuickActionsId}
									setIsEditingTitleId={setIsEditingTitleId}
									closeAllHoverPageCards={closeAllHoverPageCards}
									spaceKey={spaceKey}
									item={item}
									isContextualCreateFocused={isContextualCreateFocused}
									setIsContextualCreateFocused={setIsContextualCreateFocused}
									displayTitle={displayTitle}
								/>
							)
						}
						aria-haspopup={isHoverPageCardOptedIn ? 'dialog' : undefined}
					>
						<PageTreeItemContent
							id={item.id.toString()}
							displayHomePageIcon={item.id === spaceHomePageId}
							isDraft={isDraft}
							displayTitle={displayTitle}
							displayEmoji={displayEmoji}
							contentType={contentType}
							isSelected={selected}
							renderResourcedEmoji={renderResourcedEmoji}
							draggableState={draggableState}
							emoji={emoji}
							isExpanded={item.isExpanded}
						/>
					</PageTreeLinkItem>
				</ConditionalTreeItemTooltip>
			</ConditionalInlineRename>
		</div>
	);
};
