import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { LinkButton, type LinkButtonProps } from '@atlaskit/button/new';
import SettingsIcon from '@atlaskit/icon/core/settings';

import { COMPANY_HUB_SETTINGS, COMPANY_HUB_EDIT } from '@confluence/named-routes';
import { useHistory } from '@confluence/route-manager';

const i18n = defineMessages({
	buttonText: {
		id: 'company-hub.hub-settings.entry-button',
		defaultMessage: 'Hub Settings',
		description: 'Text for Hub settings entry point button',
	},
});

export const HubSettingsEntryButton = () => {
	const history = useHistory();

	const href = COMPANY_HUB_SETTINGS.toUrl();

	//
	// If the user comes to settings from edit, they're to return to edit, not view. Rmmember where they're coming from
	//
	let from: string | undefined;
	try {
		if (history?.location?.pathname && COMPANY_HUB_EDIT.match(history?.location?.pathname)) {
			from = COMPANY_HUB_EDIT.name;
		}
	} catch {
		// Then this click will be handled as a regular link.
	}
	const onClick: LinkButtonProps['onClick'] = from
		? (e) => {
				if (
					/* though unlikely, someone else has already handled this click */ e.defaultPrevented ||
					/* non-left mouse button clicks may open in new tabs */ e.button !== 0 ||
					/* modified clicks may open in new tabs  */ e.metaKey ||
					e.ctrlKey ||
					e.metaKey ||
					e.shiftKey
				) {
					return;
				}

				e.preventDefault();
				history?.push(href, { from });
			}
		: undefined;

	const analyticsContext = {
		source: from ? 'hubSettingsEntryButtonEditor' : 'hubSettingsEntryButton',
	};

	return (
		<LinkButton
			appearance="subtle"
			href={href}
			iconBefore={SettingsIcon}
			onClick={onClick}
			analyticsContext={analyticsContext}
		>
			<FormattedMessage {...i18n.buttonText} />
		</LinkButton>
	);
};
