import React from 'react';
import type { QueryResult } from 'react-apollo';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { DocumentSpaceTitle } from '@confluence/document-title';
import { EditContentButton } from '@confluence/edit-button';
import { fg } from '@confluence/feature-gating';
import { LoadableAfterPaint } from '@confluence/loadable';
import type {
	SpaceOverviewQueryType,
	SpaceOverviewQueryVariables,
} from '@confluence/space-overview-query';

import { HubSettingsEntryButton } from '../hub-settings/HubSettingsEntryButton';

const editButtonStyles = xcss({
	background: token('elevation.surface'),
	boxShadow: 'elevation.shadow.raised',
	borderRadius: '100%',
});

// HubPreviewHeader should not overlay the content that follows it when the scroll position is at the top.
// Pulled here out of HubPreviewHeader to avoid vertical content shift given HubPreviewHeader loads lazily.
const fixedPlaceholderStyles = xcss({
	background: token('color.background.accent.gray.subtlest'), // Match BaseHubPreviewHeader
	height: `59px`, // BREADCRUMB_HEADER_HEIGHT like FixedSpaceHeaderContainer used by SpaceOverviewGroupedButtons, but lint doesn't allow using an import or a variable
});

const headerContainerStyles = xcss({
	justifyContent: 'space-between',
	padding: 'space.250',
	position: 'absolute',
	right: 'space.0',
	width: '100%',
	alignItems: 'center',
});

const settingsButtonStyles = xcss({
	background: token('elevation.surface'),
	boxShadow: 'elevation.shadow.raised',
	borderRadius: 'border.radius.100',
});

export type CompanyHubGroupedButtonsProps = {
	contentId: string;
	spaceKey: string;
	spaceOverviewQueryResult: QueryResult<SpaceOverviewQueryType, SpaceOverviewQueryVariables>;
	versionOverride?: number | null;
};

export const CompanyHubGroupedButtons = ({
	contentId,
	spaceKey,
	spaceOverviewQueryResult,
	versionOverride,
}: CompanyHubGroupedButtonsProps) => {
	const canEdit = getCanEdit(spaceOverviewQueryResult);

	if (versionOverride != null) {
		return (
			<Box xcss={fixedPlaceholderStyles}>
				<HubPreviewHeader
					canEdit={canEdit}
					contentId={contentId}
					spaceKey={spaceKey}
					versionOverride={versionOverride}
				/>
			</Box>
		);
	}

	if (canEdit) {
		return (
			<Flex xcss={headerContainerStyles}>
				{fg('company_hub_consolidated_settings') ? (
					<Box xcss={settingsButtonStyles}>
						<HubSettingsEntryButton />
					</Box>
				) : (
					<div />
				)}
				<Box xcss={editButtonStyles}>
					<EditContentButton contentId={contentId} isCompanyHubButton />
				</Box>
				<DocumentSpaceTitle spaceKey={spaceKey} />
			</Flex>
		);
	}

	return null;
};

const getCanEdit = (
	spaceOverviewQueryResult: CompanyHubGroupedButtonsProps['spaceOverviewQueryResult'],
) =>
	Boolean(
		spaceOverviewQueryResult?.data?.space?.homepage?.operations?.some(
			(o) => o?.operation === 'update',
		),
	);

const HubPreviewHeader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-HubPreviewHeader" */ './HubPreviewHeader'))
			.HubPreviewHeader,
});
