import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { useMutation } from '@apollo/react-hooks';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import { ChromelessEditor } from '@atlaskit/editor-core/appearance-editor-chromeless';

import {
	ADD_INLINE_COMMENT_EXPERIENCE,
	ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
	ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { CommentEditor, clearCommentDraft } from '@confluence/comment';
import { useSessionData } from '@confluence/session-data';
import { markErrorAsHandled } from '@confluence/graphql';
import { useCommentContentDispatchContext } from '@confluence/comment-context';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { CreateInlineCommentMutation } from '@confluence/inline-comments-queries';
import type {
	CreateInlineCommentLocation,
	ContentRepresentation,
	CreateInlineCommentMutationData,
	CreateInlineCommentMutationVariables,
} from '@confluence/inline-comments-queries';
import { usePageInfo } from '@confluence/page-info';
import { CommentAuthor, InlineCommentFramework } from '@confluence/inline-comments-common';
import {
	parseError,
	getTranslatedError,
	isOutOfDateError,
	isHighlightError,
	isUnexpectedError,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import { useDocumentUpdateStatus } from '@confluence/annotation-provider-store';
import { i18n } from '@confluence/inline-comments-common/entry-points/i18n';
import { constructStepForGql } from '@confluence/comments-util';
import { useInlineComments, useCommentSidebarOffset } from '@confluence/inline-comments-hooks';
import { END } from '@confluence/navdex';

import { CommentSidebar } from './CommentSidebar';
import type { SelectionOptions } from './renderer/SelectionComponent';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	padding: `${token('space.150', '12px')} ${token('space.200', '16px')}`,
});

type CreateCommentProps = {
	pageId: string;
	annotationElement?: HTMLElement | null;
	selectionOptions?: SelectionOptions;
	onCreate: (annotationId: string) => void;
	onClose?: () => void;
	isEditor?: boolean;
	isOpeningMediaCommentFromToolbar?: boolean;
};

const getNodeType = (element: HTMLElement, annotationId: string) => {
	const nodeWithAnnotationWrapper = element.querySelector(`[id="${annotationId}"]`);
	if (nodeWithAnnotationWrapper) {
		const firsChildren = nodeWithAnnotationWrapper.firstChild;
		if (firsChildren?.nodeType === Node.TEXT_NODE) {
			return 'text';
		}

		const targetNode = nodeWithAnnotationWrapper.querySelector('[data-node-type]');
		if (targetNode) {
			return (targetNode as HTMLElement).dataset.nodeType;
		}
	}

	return 'unknown';
};

export const CreateComment: FC<CreateCommentProps> = ({
	pageId,
	annotationElement,
	selectionOptions,
	onClose,
	onCreate,
	isEditor,
	isOpeningMediaCommentFromToolbar,
}) => {
	const pageMode = useGetPageMode();
	const lastContentFetchTime = useRef(1);
	const sidebarEl = useRef<HTMLDivElement | null>(null);
	const commentContainerElementRef = useRef<HTMLDivElement | null>(null);
	const { documentUpdated, publishedDocumentVersion } = useDocumentUpdateStatus();

	const [shouldRefetch, setShouldRefetch] = useState(false);

	const experienceTracker = useContext(ExperienceTrackerContext);
	const { onChange, resetContentChanged } = useCommentContentDispatchContext();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { addUnresolvedInlineComment }] = useInlineComments();
	const { userId } = useSessionData();

	const sidebarOffset = useCommentSidebarOffset({
		isEditor: Boolean(isEditor),
		annotationElement,
	});

	const [createCommentFn] = useMutation<
		CreateInlineCommentMutationData,
		CreateInlineCommentMutationVariables
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
	>(CreateInlineCommentMutation);

	const { pageInfo, loading, error, refetch } = usePageInfo({
		fetchPolicy: 'network-only',
		onError: (err) => {
			experienceTracker.stopOnError({
				name: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
				error: err,
			});
		},
	});

	useEffect(() => {
		// should only refresh on the Content Out of date `Refresh` CTA is clicked
		if (documentUpdated && shouldRefetch && refetch) {
			void refetch();
			setShouldRefetch(false);
			onClose && onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentUpdated, shouldRefetch]);

	useEffect(() => {
		const metadata = document.querySelector('meta[name=confluence-request-time]');

		if (metadata) {
			const fetchTime = Number(metadata.getAttribute('content'));
			lastContentFetchTime.current = isNaN(fetchTime) ? 1 : fetchTime;
		}
	}, []);

	const handleSaveComment = (adf: JSONDocNode, onSuccess: () => void) => {
		if (!selectionOptions) {
			// We likely will never get here, but just in case
			return Promise.reject({ error: i18n.unableToGenerateStep });
		}

		experienceTracker.start({
			name: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
		});

		const { numMatches, matchIndex, originalSelection, createdFrom, step, targetNodeType } =
			selectionOptions;

		const variables = {
			input: {
				containerId: pageId,
				commentBody: {
					value: JSON.stringify(adf),
					representationFormat: 'ATLAS_DOC_FORMAT' as ContentRepresentation,
				},
				numMatches,
				matchIndex,
				originalSelection,
				createdFrom,
				lastFetchTimeMillis: lastContentFetchTime.current,
				publishedVersion: publishedDocumentVersion,
				...(!!step && { step: constructStepForGql(step) }),
			},
		};

		return createCommentFn({ variables })
			.then(({ data }) => {
				const annotationId = (data?.createInlineComment?.location as CreateInlineCommentLocation)
					?.inlineMarkerRef;
				const commentId = data?.createInlineComment?.id;

				if (!annotationId || !commentId) {
					throw new Error(
						`Comment was not saved correctly - either annotationId '${annotationId}' or commentId '${commentId}' were undefined`,
					);
				}

				// Reset the content changing flag
				resetContentChanged();

				// Grab the marker ref id returned and update the editor/renderer
				onCreate(annotationId);

				// Update the unresolved inline comments count
				addUnresolvedInlineComment(annotationId, pageMode);

				// Create inline comment track event
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'created',
						actionSubject: 'comment',
						actionSubjectId: commentId,
						objectType: 'page',
						objectId: pageId,
						source: isEditor ? 'editPageScreen' : 'viewPageScreen',
						attributes: {
							commentType: 'inline',
							parentCommentId: null,
							mode: isEditor ? 'edit' : 'view',
							isLivePage: createdFrom === 'LIVE',
							navdexPointType: END,
							inlineNodeTypes: selectionOptions.inlineNodeTypes,
							...(annotationElement &&
								!isEditor && {
									nodeType: getNodeType(annotationElement, annotationId),
								}),
							...(!isEditor && { targetNodeType }),
						},
					},
				}).fire();

				experienceTracker.succeed({
					name: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
				});

				// Reset the editor
				onSuccess();
			})
			.catch((err) => {
				let contentOutOfDate = false;
				// Get a truncated graphql error message
				const { errorId, message } = parseError(err);
				const translatedError = getTranslatedError(message);

				// COMMENTS-1879 - The backend will fall back to the old method of trying to make string matches if the content
				// is out of date. If the BE fails to apply a comment when using the old fallback method we should log it
				if (isHighlightError(translatedError)) {
					// Send the analytics event
					const analyticsObject = {
						source: 'viewPage',
						objectType: 'page',
						objectId: pageId,
						action: 'invalid',
						actionSubject: 'highlight',
						actionSubjectId: 'inlineComment',
						attributes: {
							errorMessage: message,
							editor: 'FABRIC',
							framework: InlineCommentFramework.ANNOTATION_PROVIDER,
							errorId,
							highlightOrigin: 'userHighlight', // TODO: Do we need to programmatically track this?
						},
					};

					createAnalyticsEvent({
						type: 'sendOperationalEvent',
						data: analyticsObject,
					}).fire();

					contentOutOfDate = true;
				}

				// COMMENTS-1879 - Only log unexpected errors as failures
				if (isUnexpectedError(translatedError)) {
					experienceTracker.stopOnError({
						name: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
						error: err,
					});
				} else {
					markErrorAsHandled(err);
				}

				// Use the existing value if it's already set or is an out of date error
				contentOutOfDate = contentOutOfDate || isOutOfDateError(translatedError);

				// CommentEditor expects a translated error message otherwise it fails
				// TODO: CommentEditor should likely just receive the translated message
				// rather than expect the object needed for FormattedMessage
				return Promise.reject({
					error: contentOutOfDate ? i18n.contentOutOfDate : translatedError,
					contentOutOfDate,
				});
			});
	};

	const handleOnClose = useCallback(
		(hasContentChanged?: boolean) => {
			const reason = hasContentChanged
				? 'comment discarded by user'
				: 'empty comment discarded by user';

			experienceTracker.abort({
				name: ADD_INLINE_COMMENT_EXPERIENCE,
				reason,
			});

			// Clearing reply draft is handled in the comment editor
			clearCommentDraft(isEditor ? 'edit-inline' : 'inline', 'create');

			onClose && onClose();
		},
		[experienceTracker, isEditor, onClose],
	);

	const onEditorLoad = () => {
		experienceTracker.succeed({
			name: ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
		});
	};

	if (loading || error) {
		return null;
	}

	const spaceId = pageInfo?.space?.id ?? '';
	const pageType = pageInfo?.type ?? '';

	// User page permissions
	const operations = pageInfo?.operations || [];

	// The user can upload media only if they have update permissions for the page
	const hasMediaUploadPermissions = operations.some(
		(op) => op?.operation === 'update' && op?.targetType === pageType,
	);

	return (
		<CommentSidebar
			pageId={pageId}
			onClose={handleOnClose}
			isEditor={isEditor}
			annotationElement={annotationElement}
			sidebarOffset={sidebarOffset}
			scrollIntoView
			isOpeningMediaCommentFromToolbar={isOpeningMediaCommentFromToolbar}
			sidebarEl={sidebarEl}
		>
			<Container ref={commentContainerElementRef}>
				<CommentAuthor commentMode="create" userId={userId} />
				<CommentEditor
					// @ts-ignore FIXME: `pageId` can be `undefined` here, and needs proper handling
					pageId={pageId}
					pageType={pageType}
					spaceId={spaceId}
					appearance="chromeless"
					EditorComponent={ChromelessEditor}
					onSaveComment={handleSaveComment}
					onContentChange={onChange}
					commentMode="create"
					commentType={isEditor ? 'edit-inline' : 'inline'}
					onEditorReady={onEditorLoad}
					hideWatchCheckbox
					pageMode={isEditor ? 'edit' : 'view'}
					shouldWarnOnInternalNavigation
					useNewWarningModal
					setShouldRefetch={() => setShouldRefetch(true)}
					hasMediaUploadPermissions={hasMediaUploadPermissions}
					commentContainerElementRef={commentContainerElementRef}
				/>
			</Container>
		</CommentSidebar>
	);
};
