import { useEffect, useRef, useState } from 'react';
import type { VFC } from 'react';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { NoNetworkError } from '@confluence/network';
import { usePageInfo } from '@confluence/page-info';
import {
	useDocumentUpdateStatus,
	useDocumentUpdateStatusDispatch,
} from '@confluence/annotation-provider-store';

import { MissedCommentEventsQuery } from './graphql/MissedCommentEventsQuery.graphql';
import type {
	MissedCommentEventsQuery as MissedCommentEventsQueryData,
	MissedCommentEventsQueryVariables,
	MissedCommentEventsQuery_quickReload_comments_comment_author_User as QuickReloadCommentAuthor,
} from './graphql/__types__/MissedCommentEventsQuery';

export enum PubSubState {
	ACTIVE,
	TERMINATED,
}

export enum CommentEventType {
	ALL,
	FOOTER,
	INLINE,
}

export const INACTIVE_TAB_WAIT_INTERVAL = 2000;
export const TAB_RETURN_WAIT_INTERVAL = 3000;

type MissedPubSubCommentEventsHandlerProps = {
	contentId: string;
	eventType: CommentEventType;
	subscribeToPubSubEvents: () => void;
	unsubscribeFromPubSubEvents: () => void;
	onEventFetchSuccess: (result: MissedCommentsResult) => void;
	onDocumentReturn?: () => void;
	lastPollTime?: number;
	inactiveInterval?: number;
	returnInterval?: number;
	onFetchContentVersionEvent?: () => void;
	isAutoReloadPageComments?: boolean;
};

type CommentEvent = {
	id: string;
	accountId: string;
	type: 'FOOTER' | 'INLINE';
};

export type MissedCommentsResult = {
	commentEvents: CommentEvent[];
	time: number;
	contentId: string;
};

export const MissedPubSubCommentEventsHandler: VFC<MissedPubSubCommentEventsHandlerProps> = ({
	contentId,
	eventType,
	subscribeToPubSubEvents,
	unsubscribeFromPubSubEvents,
	onEventFetchSuccess,
	onFetchContentVersionEvent,
	onDocumentReturn,
	lastPollTime,
	inactiveInterval = INACTIVE_TAB_WAIT_INTERVAL,
	returnInterval = TAB_RETURN_WAIT_INTERVAL,
	isAutoReloadPageComments = false,
}) => {
	const { documentNeedsUpdate, publishedDocumentVersion } = useDocumentUpdateStatus();
	const { setPublishedDocumentVersion } = useDocumentUpdateStatusDispatch();

	const lastEventCheckTimeRef = useRef<number>(lastPollTime || Date.now());
	const subscriptionShutdownTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
	const missedEventQueryTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
	const [documentVisible, setDocumentVisible] = useState<boolean>(!document.hidden);

	const [pubSubState, setPubSubState] = useState<PubSubState>(PubSubState.ACTIVE);

	const { refetch } = usePageInfo({
		fetchPolicy: 'cache-first', // this is required for page navigation using the page tree
		onCompleted: (data) => {
			const number = data?.content?.nodes?.[0]?.version?.number;

			if (number) {
				setPublishedDocumentVersion(number);
			}
		},
	});

	const fetchContentVersionNumber = async (): Promise<any> => {
		try {
			if (refetch) {
				const { data } = await refetch();
				return data?.content?.nodes?.[0]?.version?.number;
			}
		} catch (e) {
			if (isUnauthorizedError(e) || e instanceof NoNetworkError) {
				markErrorAsHandled(e);
			}
			return;
		}
	};

	const fetchMissingCommentEventsSinceTime = async (
		contentId: string,
		since: number,
	): Promise<MissedCommentsResult | undefined> => {
		try {
			const response = await getApolloClient().query<
				MissedCommentEventsQueryData,
				MissedCommentEventsQueryVariables
			>({
				query: MissedCommentEventsQuery,
				variables: {
					contentId,
					since,
				},
				fetchPolicy: 'no-cache',
				errorPolicy: 'all',
				context: {
					fetchOptions: {
						ignoreNoNetworkError: true,
					},
					single: true,
				},
			});

			const comments = response.data?.quickReload?.comments || [];
			let filteredCommentEvents = comments;

			// Filter out comment events tha we're not subscribed to
			if (eventType !== CommentEventType.ALL) {
				filteredCommentEvents = comments.filter((comment) =>
					eventType === CommentEventType.FOOTER
						? comment.comment?.location.type === 'FOOTER'
						: comment.comment?.location.type === 'INLINE',
				);
			}

			const commentEvents = filteredCommentEvents.map((comment) => {
				const commentId = comment.comment?.id;
				const type = comment.comment?.location.type;
				const accountId = (comment.comment?.author as QuickReloadCommentAuthor).accountId || '';
				return {
					id: commentId,
					type,
					accountId,
				} as CommentEvent;
			});

			const time = response.data?.quickReload?.time;

			return { commentEvents, time, contentId };
		} catch (e) {
			if (isUnauthorizedError(e) || e instanceof NoNetworkError) {
				markErrorAsHandled(e);
			}

			// Just do nothing
			return;
		}
	};

	const clearMissedEventTimeout = () => {
		if (missedEventQueryTimeout.current) {
			clearTimeout(missedEventQueryTimeout.current);
			missedEventQueryTimeout.current = null;
		}
	};

	const checkAndHandleMissedEvents = () => {
		// If we already have a timeout queued clear it
		clearMissedEventTimeout();

		// Check for the missed events on a timeout after the user has been back for 3 seconds
		missedEventQueryTimeout.current = setTimeout(async () => {
			if (documentNeedsUpdate) {
				return;
			}
			const contentLatestVersion = await fetchContentVersionNumber();
			if (!isAutoReloadPageComments && contentLatestVersion !== publishedDocumentVersion) {
				setPublishedDocumentVersion(contentLatestVersion);
				onFetchContentVersionEvent && onFetchContentVersionEvent();
				return;
			}

			// Call passed in fetch function
			void fetchMissingCommentEventsSinceTime(
				contentId,
				lastEventCheckTimeRef.current || Date.now(),
			).then((res) => {
				// This check of content types is needed in case the promise resolves after a user has already changed pages
				if (res && contentId === res.contentId) {
					lastEventCheckTimeRef.current = res.time;

					// Return the result to the caller
					onEventFetchSuccess(res);
				}

				// If the call fails, do nothing, just assume the events were missed
			});
		}, returnInterval);
	};

	// Wire up pub sub events when the component mounts
	useEffect(() => {
		return () => {
			window.removeEventListener('beforeunload', unsubscribeFromPubSubEvents);
			unsubscribeFromPubSubEvents();
			clearMissedEventTimeout();
		};
		// We only want to resub when the content changes or the passed in functions change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId]);

	// Need to subscribe to the pubsub when component mounts or contentId, publishedDocumentVersion changes
	useEffect(() => {
		// No need to subscribe to the pubsub when doc version is 0
		// We need the events only when we have a valid version number i.e published
		if (publishedDocumentVersion === 0) {
			return;
		}
		subscribeToPubSubEvents();
		// Make sure to unsubscribe from pubsub events if the browser closes
		window.addEventListener('beforeunload', unsubscribeFromPubSubEvents);

		// We only want to resub when the content changes or the passed in functions change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId, publishedDocumentVersion, documentNeedsUpdate]);

	// Listen to document visibility changes
	useEffect(() => {
		const changeVisibility = () => setDocumentVisible(!document.hidden);
		document.addEventListener('visibilitychange', changeVisibility);

		return () => {
			document.removeEventListener('visibilitychange', changeVisibility);
		};
	}, [setDocumentVisible]);

	useEffect(() => {
		if (documentVisible && subscriptionShutdownTimer.current) {
			clearTimeout(subscriptionShutdownTimer.current);
			subscriptionShutdownTimer.current = null;
		}

		// If the document is no longer visible, set a timeout to unsubscribe from pubsub events
		if (
			!documentVisible &&
			!subscriptionShutdownTimer.current &&
			pubSubState === PubSubState.ACTIVE
		) {
			subscriptionShutdownTimer.current = setTimeout(() => {
				unsubscribeFromPubSubEvents();
				setPubSubState(PubSubState.TERMINATED);
			}, inactiveInterval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentVisible]);

	// Resubscribe to the pubsub events when we come back and handle any missed events
	useEffect(() => {
		setPubSubState((prevState) => {
			if (documentVisible && prevState === PubSubState.TERMINATED) {
				onDocumentReturn && onDocumentReturn();
				checkAndHandleMissedEvents();
				subscribeToPubSubEvents();
				return PubSubState.ACTIVE;
			}

			return prevState;
		});
		// We only want this to fire when the document visibility has changed, not the pubsub state
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentVisible]);

	return null;
};
