import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import ErrorIcon from '@atlaskit/icon/core/error';
import { Flex, Box, xcss } from '@atlaskit/primitives';

const errorContainerStyles = xcss({
	paddingLeft: 'space.100',
	paddingTop: 'space.100',
	paddingBottom: 'space.150',
	boxSizing: 'border-box',
	height: '40px',
	width: 'fit-content',
});

const errorTextStyles = xcss({
	lineHeight: '24px',
	cursor: 'default',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	color: 'color.text',
});

export const BlogTreeErrorComponent = () => {
	return (
		<Box xcss={errorContainerStyles}>
			<Tooltip
				content={
					<FormattedMessage
						id="blog-tree.failed-to-load.tooltip"
						defaultMessage="Hold tight, we’re working on fixing this."
						description="Tooltip when hovering over blog tree error component"
					/>
				}
				position="bottom"
			>
				<Flex gap="space.050">
					<ErrorIcon label="error" color={token('color.icon.danger')} />
					<Box xcss={errorTextStyles}>
						<FormattedMessage
							id="blog-tree.failed-to-load.error.message"
							defaultMessage="Blog tree failed to load"
							description="Message when blog tree fails to load"
						/>
					</Box>
				</Flex>
			</Tooltip>
		</Box>
	);
};
