import { useCallback, useMemo } from 'react';
import uuid from 'uuid/v4';

import { getAGGClient, useQueryNoFurtherUpdate } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { useContentType, usePageContentId } from '@confluence/page-context';

import { HighlightKeywordsQuery } from './gql/HighlightKeywordsQuery.agggraphql';
import { generatePageARI, generateWorkspaceId } from './reading-aids-utils';
import {
	type HighlightKeywordsQueryType,
	type HighlightKeywordsQueryVariables,
	KeyPhraseCategory,
} from './gql/__types__/HighlightKeywordsQuery';

type useHighlightAcronymsArgs = {
	skipNetworkCall?: boolean;
};

export type HighlightPhraseNodeType = {
	phrase: string;
	category: KeyPhraseCategory;
};

type HighlightAcronymsType = {
	acronyms: HighlightPhraseNodeType[];
	acronymRegex: RegExp;
	loading: boolean;
};

type QueryHighlightAcronymsType = {
	acronymRegex?: RegExp;
	acronyms: HighlightPhraseNodeType[];
};

const cachedAcronymRegex = {
	pageARI: '',
	regex: new RegExp('(?!)'),
};

const getAcronymRegex = (
	acronyms: HighlightPhraseNodeType[],
	pageARI: string = '',
	ignoreCase: boolean = false,
): RegExp => {
	if (pageARI === cachedAcronymRegex.pageARI) {
		return cachedAcronymRegex.regex;
	}
	let regex;
	try {
		regex = new RegExp(
			acronyms.map((node) => `\\b${node.phrase}\\b`).join('|'),
			ignoreCase ? 'gi' : 'g',
		);
	} catch (_e) {
		regex = new RegExp('(?!)');
	}
	cachedAcronymRegex.pageARI = pageARI;
	cachedAcronymRegex.regex = regex;
	return regex;
};

export const useHighlightAcronyms = ({
	skipNetworkCall = false,
}: useHighlightAcronymsArgs): HighlightAcronymsType => {
	const [contentId] = usePageContentId();
	const [contentType] = useContentType();
	const { cloudId, activationId } = useSessionData();
	const pageARI = contentId ? generatePageARI(contentId, cloudId, contentType) : undefined;
	const workspaceId = generateWorkspaceId(activationId, cloudId);

	const { data, loading, error } = useQueryNoFurtherUpdate<
		HighlightKeywordsQueryType,
		HighlightKeywordsQueryVariables
	>(HighlightKeywordsQuery, {
		variables: {
			pageARI,
			workspaceId,
		},
		errorPolicy: 'ignore',
		skip: !workspaceId || !pageARI || skipNetworkCall,
		client: getAGGClient(),
	});

	const keyPhrases = useMemo(() => {
		return !loading && !error && data?.knowledgeDiscovery?.keyPhrases?.nodes
			? data.knowledgeDiscovery.keyPhrases.nodes.map((node) => ({
					phrase: node.keyPhrase,
					category: node.category,
				}))
			: [];
	}, [data, loading, error]);

	const acronymRegexMemo = useMemo(() => {
		let acronymRegex = new RegExp('(?!)');
		if (keyPhrases.length) {
			acronymRegex = getAcronymRegex(keyPhrases, pageARI, true);
		}
		return acronymRegex;
	}, [pageARI, keyPhrases]);

	return useMemo(
		() => ({
			acronyms: keyPhrases,
			acronymRegex: acronymRegexMemo,
			loading,
		}),
		[acronymRegexMemo, loading, keyPhrases],
	);
};

export const useQueryHighlightAcronyms = () => {
	const [contentId] = usePageContentId();
	const [contentType] = useContentType();
	const { cloudId, activationId } = useSessionData();
	const pageARI = contentId ? generatePageARI(contentId, cloudId, contentType) : undefined;
	const workspaceId = generateWorkspaceId(activationId, cloudId);

	const queryHighlightAcronyms = useCallback(
		async ({
			cacheKey = uuid(),
		}: { cacheKey?: string } = {}): Promise<QueryHighlightAcronymsType> => {
			if (!workspaceId || !pageARI) {
				return {
					acronyms: [],
				};
			}

			const client = getAGGClient();
			try {
				const { data } = await client.query<
					HighlightKeywordsQueryType,
					HighlightKeywordsQueryVariables
				>({
					query: HighlightKeywordsQuery,
					fetchPolicy: 'network-only',
					errorPolicy: 'ignore',
					variables: {
						// comment from confluence/next/packages/content-appearance/src/contentAppearance.ts
						// This is dummy variable is used to force "network-only" requests to only be run as network calls.
						// A bug in Apollo sometimes makes this query return data from the cache even if the policy is "network-only"
						// See for more details: https://pug.jira-dev.com/wiki/spaces/~683304838/pages/5971970200/Apollo+Client+-+weird+refetch+behavior
						cacheKey,
						pageARI,
						workspaceId,
					},
				});

				const keyPhrases = data?.knowledgeDiscovery?.keyPhrases?.nodes
					? data.knowledgeDiscovery.keyPhrases.nodes.map((node) => ({
							phrase: node.keyPhrase,
							category: node.category,
						}))
					: [];

				// getAcronymRegex use pageARI to cache the regex for the page
				// but for live edit pages we should be careful with the cache
				// because the page content is changing constantly
				const pageARIWithCacheKey = `${pageARI}-${cacheKey}`;
				let acronymRegex: RegExp | undefined = undefined;
				if (keyPhrases.length) {
					acronymRegex = getAcronymRegex(keyPhrases, pageARIWithCacheKey, true);
				}

				return {
					acronyms: keyPhrases,
					acronymRegex,
				};
			} catch (err) {
				return {
					acronyms: [],
				};
			}
		},
		[pageARI, workspaceId],
	);

	return { queryHighlightAcronyms };
};
