import { Text } from '@atlaskit/primitives';
import React from 'react';
import type { ApolloError } from 'apollo-client';

import { getStatusCode, isStatusCodeError } from '@confluence/error-boundary';

import { i18n } from './movePublishedPageErrorMessages';

const isConflictingPageNameError = (errorMessage) => {
	return (
		(errorMessage.includes('A page with the name(s)') ||
			errorMessage.includes('A content with the name(s)')) &&
		errorMessage.includes('already exist(s) in the space')
	);
};

const convertToCommaSeparatedString = (listOfPageNames) => {
	if (listOfPageNames.length === 2) {
		return listOfPageNames.join(' and ');
	} else if (listOfPageNames.length > 2) {
		return `${listOfPageNames
			.slice(0, listOfPageNames.length - 1)
			.join(', ')}, and ${listOfPageNames[listOfPageNames.length - 1]}`;
	}
	return listOfPageNames.join(', ');
};

const getErrorMessageForConflictingPageNamesError = (
	errorMessage,
	IsInEditor?: boolean,
	onActionClick?,
	isLivePage?: boolean,
) => {
	let firstPartOfMessage = 'A page with the name(s) ';
	const middlePartOfMessage = ' already exist(s) in the space ';
	let lastPartOfMessage = '. You must rename the page title before moving it.';

	if (errorMessage.indexOf(firstPartOfMessage) === -1) {
		firstPartOfMessage = 'A content with the name(s) ';
		lastPartOfMessage = '. You must rename the content title before moving it.';
	}

	const listOfConflictingNames = errorMessage
		.substring(
			errorMessage.indexOf(firstPartOfMessage) + firstPartOfMessage.length,
			errorMessage.indexOf(middlePartOfMessage),
		)
		.split(',');

	const destinationSpace = errorMessage.substring(
		errorMessage.indexOf(middlePartOfMessage) + middlePartOfMessage.length,
		errorMessage.indexOf(lastPartOfMessage),
	);
	if (listOfConflictingNames.length === 1) {
		// Show different messages depending on whether the move page dialog is triggered from within the editor or outside of it
		const title = IsInEditor
			? i18n.singleConflictingPageNameWithinEditorErrorTitle
			: i18n.singleConflictingContentNameErrorTitle;

		let description;
		if (isLivePage) {
			description = i18n.singleConflictingLivePageNameWithinEditorErrorDescription;
		} else if (IsInEditor) {
			description = i18n.singleConflictingPageNameWithinEditorErrorDescription;
		} else {
			description = i18n.singleConflictingContentNameErrorDescription;
		}

		const actions = {
			...(IsInEditor && {
				actions: [
					{
						key: 'move-page-error-error-cta',
						text: i18n.singleConflictingPageNameWithinEditorErrorCallToAction,
						onClick: onActionClick,
					},
				],
			}),
		};

		return {
			title,
			description,
			props: {
				values: {
					conflictingPageName: listOfConflictingNames[0],
					destinationSpace,
					strong: (chunks) => <Text as="strong">{chunks}</Text>,
				},
			},
			...actions,
		};
	} else {
		return {
			title: i18n.multipleConflictingContentNamesErrorTitle,
			description: i18n.multipleConflictingContentNamesErrorDescription,
			props: {
				values: {
					listOfConflictingNames: convertToCommaSeparatedString(listOfConflictingNames),
					destinationSpace,
				},
			},
		};
	}
};

const isRestrictionsPermissionError = (errorMessage) => {
	return (
		errorMessage.includes('You can not restrict pages in the destination space.') ||
		errorMessage.includes('You can not restrict contents in the destination space.')
	);
};

const isNotSpaceAdminForCrossSpaceMoveError = (errorMessage) => {
	return errorMessage.includes('Only space admins can move content to another space.');
};

// isTargetSameasSource and isParentChildLoop are the same error, but the api either returns 400/500.
const isParentChildLoopError = (errorMessage) => {
	return errorMessage.includes('creates a parent-child loop');
};

const isTargetSameAsSourceError = (errorMessage) => {
	return errorMessage.includes('Can not set page as its own parent.');
};

const isDestinationNonexistantError = (errorMessage) => {
	return (
		errorMessage.includes('The target page provided does not exist.') ||
		errorMessage.includes('The target content provided does not exist.') ||
		((errorMessage.includes('The target page with ID = ') ||
			errorMessage.includes('The target content with ID = ')) &&
			errorMessage.includes('is archived'))
	);
};

const isSourceNonexistantError = (errorMessage) => {
	return (
		errorMessage.includes('The source page provided does not exist.') ||
		errorMessage.includes('The source content provided does not exist.') ||
		((errorMessage.includes('The source page with ID = ') ||
			errorMessage.includes('The source content with ID =')) &&
			errorMessage.includes('is archived'))
	);
};

const isCurrentlyUpdatedError = (errorMessage) =>
	errorMessage.includes('UnexpectedRollbackException');

const isMovingTooManyPagesError = (errorMessage) =>
	errorMessage.includes("We can't support moving this many pages at this time");

const isMovingMoreThan100PagesError = (errorMessage) =>
	errorMessage.includes("We can't support moving more than 100 pages at this time");

const isAttemptingToMoveContentToSelf = (errorMessage) =>
	errorMessage.includes('Invalid to perform any move operation where source is same as target');

const isPartiallyMovedError = (errorMessage) =>
	errorMessage.includes("Can't add a parent from another space");

const getI18nMessageForKnown400Error = (
	errorMessage,
	IsInEditor?: boolean,
	onActionClick?,
	isLivePage?: boolean,
) => {
	if (isConflictingPageNameError(errorMessage)) {
		return getErrorMessageForConflictingPageNamesError(
			errorMessage,
			IsInEditor,
			onActionClick,
			isLivePage,
		);
	} else if (isAttemptingToMoveContentToSelf(errorMessage)) {
		return {
			title: i18n.movingContentToSelfErrorTitle,
			description: i18n.movingContentToSelfErrorDescription,
		};
	} else if (isParentChildLoopError(errorMessage)) {
		return {
			title: i18n.parentChildLoopErrorTitle,
			description: i18n.parentChildLoopErrorDescription,
		};
	} else if (isRestrictionsPermissionError(errorMessage)) {
		return {
			title: i18n.restrictionsPermissionErrorTitle,
			description: i18n.restrictionsPermissionErrorDescription,
		};
	} else if (isMovingMoreThan100PagesError(errorMessage)) {
		return {
			title: i18n.moreThan100PagesErrorTitle,
			description: i18n.moreThan100PagesErrorDescription,
		};
	} else if (isMovingTooManyPagesError(errorMessage)) {
		// This was previously a configurable limit, but the blanket 100-page limit (isMovingMoreThan100PagesError)
		// has been added behind a feature gate on the backend. Thus, this branch is still needed
		return {
			title: i18n.tooManyPagesErrorTitle,
			description: i18n.tooManyPagesErrorDescription,
		};
	}
	return undefined;
};

const getI18nMessageForKnown500Error = (errorMessage) => {
	if (isTargetSameAsSourceError(errorMessage)) {
		return {
			title: i18n.parentChildLoopErrorTitle,
			description: i18n.parentChildLoopErrorDescription,
		};
	} else if (isCurrentlyUpdatedError(errorMessage)) {
		return {
			title: i18n.currentlyUpdatedErrorTitle,
			description: i18n.currentlyUpdatedErrorDescription,
		};
	} else if (isPartiallyMovedError(errorMessage)) {
		return {
			title: i18n.partiallyMovedErrorTitle,
			description: i18n.partiallyMovedErrorDescription,
		};
	}
	return undefined;
};

const getI18nMessageForKnown404Error = (errorMessage) => {
	if (isDestinationNonexistantError(errorMessage)) {
		return {
			title: i18n.destinationDoesNotExistErrorTitle,
			description: i18n.destinationDoesNotExistErrorDescription,
		};
	} else if (isSourceNonexistantError(errorMessage)) {
		return {
			title: i18n.sourceDoesNotExistErrorTitle,
			description: i18n.sourceDoesNotExistErrorDescription,
		};
	}
	return undefined;
};

const getI18nMessageForKnown403Error = (errorMessage) => {
	if (isNotSpaceAdminForCrossSpaceMoveError(errorMessage)) {
		return {
			title: i18n.notSpaceAdminForCrossSpaceMoveTitle,
			description: i18n.notSpaceAdminForCrossSpaceMoveDescription,
		};
	} else {
		return {
			title: i18n.permissionsErrorTitle,
			description: i18n.permissionsErrorDescription,
		};
	}
};

export const getI18nMessageForKnownError = (
	error: ApolloError,
	{ IsInEditor = false, onActionClick = () => {}, isLivePage = false } = {},
) => {
	const graphQLError = error?.graphQLErrors?.[0];
	const errorStatus = getStatusCode(graphQLError);
	const errorMessage = graphQLError?.message;
	if (errorStatus === '400') {
		return getI18nMessageForKnown400Error(errorMessage, IsInEditor, onActionClick, isLivePage);
	} else if (errorStatus === '403') {
		return getI18nMessageForKnown403Error(errorMessage);
	} else if (errorStatus === '404') {
		return getI18nMessageForKnown404Error(errorMessage);
	} else if (errorStatus === '500') {
		return getI18nMessageForKnown500Error(errorMessage);
	}
	return undefined;
};

export const getI18nMessageForUnknownError = (error: ApolloError, childPagesCount: number) => {
	if (isStatusCodeError(error, '503') && childPagesCount >= 500) {
		return {
			title: i18n.tooManyPagesErrorTitle,
			description: i18n.tooManyPagesErrorDescription,
		};
	}

	return {
		title: i18n.defaultErrorTitle,
		description: i18n.defaultErrorDescription,
	};
};

export const getI18nMessageForNoCreatePermissions = () => ({
	title: i18n.noCreatePermissionsErrorTitle,
	description: i18n.noCreatePermissionsErrorDescription,
});
