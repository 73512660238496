import { LoadableLazy } from '@confluence/loadable';

export const PageTreeQuickActions = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-PageTreeQuickActions" */ './PageTreeQuickActions'))
			.PageTreeQuickActions,
});

export type InlineRenameProps = {
	children: any;
	id: string;
	title: string;
	displayEmoji: string;
	isEditingTitle: boolean;
	setIsEditingTitleId: (isEditingTitleId: string | null) => void;
	updateContentTreeItem: (id: string, data: object) => void;
	isNewContent?: boolean;
	setFocusedQuickActionsId: (focusedQuickActionsId: string | null) => void;
	contentStatus?: string;
	contentType?: string;
	indent?: number;
};

export const InlineRename = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-InlineRename" */ './InlineRename')).InlineRename,
	loading: ({ children }) => children,
});

export { ConditionalInlineRename } from './ConditionalInlineRename';
