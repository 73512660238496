export const KEY_PHRASES_OPERATION_NAME = 'PlatformHighlightKeywordsQuery';
export const KeyPhrasesQuery = `
	query ${KEY_PHRASES_OPERATION_NAME}(
		$entityAri: String!
		$workspaceId: String!
		$jiraReporterAccountId: String
		$jiraAssigneeAccountId: String
		$inputText: KnowledgeDiscoveryKeyPhraseInputText
	) {
		knowledgeDiscovery  {
			keyPhrases(entityAri: $entityAri, workspaceId: $workspaceId, jiraReporterAccountId: $jiraReporterAccountId, jiraAssigneeAccountId: $jiraAssigneeAccountId, inputText: $inputText) 
				@optIn(to: "KnowledgeDiscovery Get Key Phrases") {
				__typename
				... on KnowledgeDiscoveryKeyPhraseConnection {
					nodes {
						... on KnowledgeDiscoveryKeyPhrase {
							keyPhrase
							category
						}
					}
				}
				... on QueryError {
					message
				}
			}
		}
	}
`;
